<template>
  <div>
      <v-container
        class="text-center"
        fill-height
        style="height: calc(100vh - 100px);"
      >
        <v-row align="center">
          <v-col>
            <h1 class="display-2 primary--text">Whoops, 404</h1>

            <p>The page you were looking for does not exist</p>

            <v-btn :to="{ name: 'HomeMain' }" color="primary" outlined>
              Back to {{ business.name }}
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
  </div>
</template>
<script>

export default {
  computed: {
    business() {
      return this.$store.state.business
    }
  }
};
</script>
