<template>
  <div>
    <Navigation />
    <v-main>
      <v-container
        class="text-center"
        fill-height
        style="height: calc(100vh - 58px);"
      >
        <v-row align="center">
          <v-col>
            <h1 class="display-2 primary--text">Whoops, 404</h1>

            <p>The page you were looking for does not exist</p>

            <v-btn :href="fpUrl()" color="primary" outlined>
              Get me out of here!
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </div>
</template>
<script>
import Navigation from "@/components/Navigation";

export default {
  name: "FourOhFour",
  methods: {
    fpUrl() {
      return process.env.VUE_APP_FEEPLUS_URL;
    },
  },
  components: {
    Navigation
  }
};
</script>
